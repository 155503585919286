<template>
  <div>
    <ItemNavFooter :title="title" :data="listNav" class="md:hidden flex"></ItemNavFooter>
    <div class="flex-col gap-3 md:flex hidden">
      <p class="text-black text-15px leading-6 font-medium">{{ title }}</p>
      <ul class="flex flex-col gap-3">
        <li
            class="text-bw-05 hover:text-primary duration-400 text-15px leading-6"
            v-for="(item, index) in listNav"
            :key="index"
        >
          <nuxt-link v-if="item?.url?.startsWith('http')"
                     :to="item.url"
                     target="_blank">
            {{ item.name }}
          </nuxt-link>
          <nuxt-link v-else
                     :to="item?.url">
            {{ item?.name }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import ItemNavFooter from './itemNavFooter.vue'

const props = defineProps({
  title: {
    type: String
  },
  listNav: {
    type: Array
  }
});

let listNav = props?.listNav?.filter(item => item?.url != null)
</script>

<style></style>
